import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Box = makeShortcode("Box");
const Link = makeShortcode("Link");
const InfoBlock = makeShortcode("InfoBlock");
const Button = makeShortcode("Button");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const Column = makeShortcode("Column");
const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-perfecting-language",
      "style": {
        "position": "relative"
      }
    }}>{`🙊 Perfecting Language`}<a parentName="h1" {...{
        "href": "#-perfecting-language",
        "aria-label": " perfecting language permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Aligner center sx={{
      fontStyle: 'italic'
    }} mdxType="Aligner">
      <Box mdxType="Box">
Nor grieve, dear heart,<br />  
nor fear the seeming.<br />   
Here is waking in the dreaming.<br /> 
        <br />
– <Link to="http://gutenberg.net.au/ebooks06/0602001h.html" mdxType="Link">Abraham Merritt</Link> (adapted)
      </Box>
    </Aligner>  
    <p>{`Is it possible to guard effectively against the inertia of language? Arguably, we cannot
achieve this in natural language, but might we be able to develop a "perfect language" in
which I can say exactly what I mean, you can understand exactly what I meant, and I can be
sure you have understood it exactly? Many people have tried to answer these questions, so let's go on a
`}<a parentName="p" {...{
        "href": "https://tinyurl.com/shannon-math-comms"
      }}>{`mathematical tour`}</a>{` through universal languages and the incomplete search for linguistic perfection. `}</p>
    <blockquote>
      <p parentName="blockquote">{`The crucial point is that knowing this `}{`[perfect]`}{` language would be like having a key to universal
knowledge. If you’re a theologian, it would bring you closer, very close, to God’s thoughts,
which is dangerous. If you’re a magician, it would give you magical powers. If you’re a
linguist, it would tell you the original, pure, uncorrupted language from which all languages
descend.`}</p>
    </blockquote>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Search`}</strong></p>
        <p><img parentName="p" {...{
            "src": "/images/mod7-img/riemann-zeta.jpg",
            "alt": "The Search"
          }}></img></p>
        <Aligner center mdxType="Aligner">
          <Button to="https://inference-review.com/article/the-perfect-language" mdxType="Button">
    Read
          </Button>
        </Aligner>
      </Box>
    </InfoBlock>
    <h3 {...{
      "id": "how-does-this-fit-into-kernel",
      "style": {
        "position": "relative"
      }
    }}>{`How does this fit into Kernel?`}<a parentName="h3" {...{
        "href": "#how-does-this-fit-into-kernel",
        "aria-label": "how does this fit into kernel permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The sacred is not perfect. The `}<em parentName="p">{`source`}</em>{` of what we call "sacred" is beyond any label,
including "perfect". When we conflate the two, we propagate violent and oppressive ideologies
which are convinced of their own correctness or truth. The sacred as we experience it in every
day life is undecidable, irreducible, paradoxical.`}</p>
    <p>{`When we accept this fact, as this article will show you, it reveals some really interesting
and practical contexts in which we can more closely approach a perfection we know to be
inherently irreducible. This is a truly beautiful thing to do. It is the best way to close
this open-ended course. Thank you all, and we wish you a `}<Link to="https://unifying.thebluebook.co.za" mdxType="Link">{`peace`}</Link>{` past any understanding.`}</p>
    <h2 {...{
      "id": "brief",
      "style": {
        "position": "relative"
      }
    }}>{`Brief`}<a parentName="h2" {...{
        "href": "#brief",
        "aria-label": "brief permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Gregory Chaitin begins his essay by discussing Umberto Eco's book `}<em parentName="p"><Link to="https://www.goodreads.com/book/show/10513.The_Search_for_the_Perfect_Language" mdxType="Link">{`The Search for the Perfect Language`}</Link></em>{`, and how it relates to some deep concepts in mathematics, coupled with a non-standard view on intellectual history. He starts by asking,`}</p>
    <blockquote>
      <p parentName="blockquote">{`What is the perfect language? `}{`[... It is]`}{` the language whose structure directly expresses the
structure of the world, in which concepts are expressed in their `}<Link to="https://www.youtube.com/playlist?list=PL5ClmaG2tnPPFveZLH5lRrzV-Ndc3_Nli" mdxType="Link">{`direct, original`}</Link>{` format.`}</p>
    </blockquote>
    <p>{`He discusses some of the early figures of this search, including Raymond Lull and Gottfried
Leibniz. Though both grounded in magical and theological thinking - as well as alchemy and
hermeticism - it was Leibniz who first formulated the search in a modern way, calling this
language the `}<em parentName="p">{`characteristica universalis. A`}</em>{` crucial part of this language was something he
called the `}<em parentName="p">{`calculus`}</em>{` `}<em parentName="p">{`ratiocinator`}</em>{`, by which we can reduce reasoning to calculation and
thereby elide the need for opinionated arguments in favour of pure computation.`}</p>
    <p>{`It was this notion which led him to develop calculus. Leibniz's version was slightly different
from Newton's, in that his focus was specifically on the notation and formalism: how can
we generate the most expressive power from the smallest set of meaningful symbols? His calculus
presents a fundamentally mechanistic approach to proof, as he saw clearly the importance of
having a formalism that led you automatically to the answer.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: A perfect language is one whose words directly express the original what?`}</p>
        <p>{`Structure of the world.`}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "set-and-setting",
      "style": {
        "position": "relative"
      }
    }}>{`Set and Setting`}<a parentName="h3" {...{
        "href": "#set-and-setting",
        "aria-label": "set and setting permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Chaitin then describes the theory of infinite sets as `}<em parentName="p">{`mathematical theology`}</em>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Cantor’s goal was to understand God. God is transcendent. The theory of infinite sets has a
hierarchy of bigger and bigger infinities, the alephs, the ℵ’s, and so on.`}</p>
    </blockquote>
    <p>{`These sets go on forever, as you can always make a new set out of the set of all previous sets,
or by taking a union of all the members of an infinite sequence to get ever greater infinities.
However, this leads to a contradiction, discovered by Bertrand Russell and now called the
Russell paradox: if we take the universal set - the set of everything - and then consider the
set of all subsets in it, then this set of all subsets must necessarily be bigger than the
universal set. But how can this be? The set of all subsets of the universal set can neither be
contain itself nor not contain itself - which poses a problem for mathematicians who are not
theologically inclined.`}</p>
    <p>{`While this contradiction never bothered Cantor - who took the view that it’s paradoxical for a
finite being to try to comprehend a transcendent, infinite being, so paradoxes are fine - it
led Russell to expose numerous contradictions in mathematical reasoning. As a response to this,
another mathematician, David Hilbert, developed a formal axiomatic theory,
which is a modern version of Leibniz’s `}<em parentName="p">{`characteristica universalis`}</em>{` and `}<em parentName="p">{`calculus ratiocinator`}</em>{`.`}</p>
    <p>{`Axiomatic theories are written not in natural language, with its ambiguity and informal
reasoning, but in precise, artificial languages based on mathematical logic which specifies
the rules of the game precisely.`}</p>
    <h3 {...{
      "id": "empty-in-completeness",
      "style": {
        "position": "relative"
      }
    }}>{`Empty In Completeness`}<a parentName="h3" {...{
        "href": "#empty-in-completeness",
        "aria-label": "empty in completeness permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`The dream that there is a finite set of axioms which would allow us, in principle, to deduce all
mathematical truth led to some truly beautiful work, in particular Zermelo–Fraenkel set theory
and von Neumann integers.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Baruch Spinoza had a philosophical system in which the world is built out of only one
substance, and that substance is God, that’s all there is. Zermelo–Fraenkel set theory is
similar. Everything is sets, and every set is built out of the empty set. That’s all there is:
the empty set, and sets built starting with the empty set.`}</p>
    </blockquote>
    <p>{`However, Gödel and Turing showed in the 1930's that you can’t have a perfect language or a
formal axiomatic theory for `}<strong parentName="p">{`all`}</strong>{` of mathematics because of 'incompleteness'. Gödel began with the
statement "This statement is unprovable" and proceeded to show how the paradox at the heart of
this (using `}<Link to="https://en.wikipedia.org/wiki/G%C3%B6del_numbering" mdxType="Link">{`Gödel numbering`}</Link>{`) reveals that we cannot capture all mathematical truth in any theory.`}</p>
    <p>{`Turing derived incompleteness from a more fundamental phenomenon:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Turing’s insight in 1936 was that incompleteness, which Gödel found in 1931, for any formal
axiomatic theory, comes from a deeper phenomenon, which is uncomputability. Incompleteness is
an immediate corollary of uncomputability, a concept which does not appear in Gödel’s 1931 paper.`}</p>
    </blockquote>
    <p>{`The fact that Gödel's incompleteness is a result of uncomputability suggests that, while there
is no perfect mathematical language, `}<strong parentName="p">{`there are perfect languages for certain computations`}</strong>{`.`}</p>
    <blockquote>
      <p parentName="blockquote">{`What Turing discovered in 1936 is that there’s a kind of completeness called universality and
that there are universal Turing machines and universal programming languages `}{`[... that is,]`}{` a
language in which every possible algorithm can be written.`}</p>
    </blockquote>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: What paradoxical statement did Gödel use to prove incompleteness?`}</p>
        <p>{`This statement is unprovable.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Turing showed that incompleteness is actually an immediate corollary of what?`}</p>
        <p>{`Prompt: Uncomputability.`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Uncomputability shows that there are no perfect languages, though there are universal ones. A universal language is one in which what can be written?`}</p>
        <p>{`Every possible algorithm. `}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "algorithms-and-information",
      "style": {
        "position": "relative"
      }
    }}>{`Algorithms and Information`}<a parentName="h3" {...{
        "href": "#algorithms-and-information",
        "aria-label": "algorithms and information permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`Chaitin goes on to describe his own work in Algorithmic Information Theory, which derives
incompleteness from an extreme form of uncomputability, called algorithmic irreducibility.
A deeper understanding of the mathematically irreducible information contained in the Halting
Problem allows us to`}</p>
    <blockquote>
      <p parentName="blockquote">{`pick out, from Turing’s universal languages, maximally expressive programming languages,
which are maximally compact.`}</p>
    </blockquote>
    <p>{`AIT is really about deducing the size of the smallest program required to calculate something,
which allows us to create "better" notions of perfection, defined in terms of `}<strong parentName="p">{`conciseness`}</strong>{`. `}</p>
    <blockquote>
      <p parentName="blockquote">{`The most expressive languages are the ones with the smallest programs. This definition of
complexity is dry and technical. But let me put this into medieval terminology, which is much
more colorful. What we’re asking is, how many yes/no decisions did God have to make to create
something?—which is obviously a rather basic question to ask, if you consider that God is
calculating the universe `}{`[...]`}{` God will naturally use the most perfect, most powerful
programming languages, when he creates the world, to build everything.`}</p>
    </blockquote>
    <p>{`These most powerful programming languages can be expressed succinctly in AIT by considering a
particular class of universal Turing machines `}<strong parentName="p"><em parentName="strong">{`U`}</em></strong>{`, and the most efficient ways for these
machines to be universal. Chaitin describes some of the ways we have of calculating this,
which extend more deeply in the details of AIT than this brief will go. `}</p>
    <p>{`What's most relevant is how we can define programs in `}<strong parentName="p">{`self-delimiting ways`}</strong>{`. That is, how
can we get our universal Turing machine `}<strong parentName="p"><em parentName="strong">{`U`}</em></strong>{` to know when a program has ended without adding
an extra symbol (i.e. just using 0 and 1, no blanks); or extra information about the size of
the program to the program itself, which would make any computation less concise than the ideal.`}</p>
    <p>{`A self-delimiting program is one which `}<a parentName="p" {...{
        "href": "https://youtu.be/41b254BcMJM?t=640"
      }}>{`knows when to stop`}</a>{`. This is an elegant and simple idea which is rather difficult to understand in practice but, as always, close observation of the world around us should unearth some clues in surprising places. The video linked above reveals how deeper understanding of such phenomena suggest ever more succinct universal languages, but open the door to - for instance - regenerative abilities and new medicine.`}</p>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`Prompt: "Better" universal languages for computation are defined in terms of what linguistic feature? `}</p>
        <p>{`Conciseness. `}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: Are the most expressive languages those with the biggest or smallest programs?`}</p>
        <p>{`Smallest. `}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Prompt: The most efficient universal languages can be used to define programs in what kind of way?`}</p>
        <p>{`Self-delimiting. `}</p>
      </Card>
    </Flash>
    <h3 {...{
      "id": "just-write",
      "style": {
        "position": "relative"
      }
    }}>{`Just Write`}<a parentName="h3" {...{
        "href": "#just-write",
        "aria-label": "just write permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <blockquote>
      <p parentName="blockquote">{`These self-delimiting binary languages are the ones that the study of program-size complexity
has led us to discriminate as the ideal languages, the most perfect languages. We got to them
in two stages, 1960s AIT and 1970s AIT. These are languages for computation, for expressing
algorithms, not for mathematical reasoning. They are universal programming languages that are
maximally expressive, maximally concise.`}</p>
    </blockquote>
    <p>{`What does this mean for the search for a perfect language? Well, it's a bit of a mixed bag.
Hilbert's formal axiomatic theory—meant to establish all mathematical truth—is necessarily
incomplete, and all formal reasoning has been proven to have a limit. However, `}</p>
    <blockquote>
      <p parentName="blockquote">{`There are `}<strong parentName="p">{`perfect languages for computing`}</strong>{`. We have universal Turing machines and
universal programming languages, and although languages for reasoning cannot be complete,
these universal programming languages are complete. Furthermore, AIT has picked out the most
expressive programming languages, the ones that are particularly good to use for a theory of
program-size complexity.`}</p>
    </blockquote>
    <p>{`Practically speaking, the search for the perfect language has yielded some truly fascinating
results. Theoretically, it has shown that mathematics contains infinite irreducible complexity
and so there is no hope of finding a simple and elegant Theory of Everything like Hilbert
imagined. That dream turned out to be a golem, but`}</p>
    <blockquote>
      <p parentName="blockquote">{`from the perspective of the Middle Ages, I would say that the perfect languages that we’ve found have given us some magical, God-like power, which is that we can breathe life into some inanimate matter. Observe that hardware is analogous to the body, and software is analogous to the soul, and when you put software into a computer, this inanimate object comes to life and creates virtual worlds.`}</p>
    </blockquote>
    <p>{`Importantly, `}<a parentName="p" {...{
        "href": "https://youtu.be/WV-naO9ULp8?t=1169"
      }}>{`such power is limited`}</a>{` by both the imagination of the programmer and by physical reality: this `}<a parentName="p" {...{
        "href": "/learn/module-metta/relating-kernel/"
      }}>{`web of correlated interactions`}</a>{`. We may choose and work to accept with humour and love (i) our own limitations, (ii) the constraints of the environment within which we are embedded, and (iii) the affordances of our `}<a parentName="p" {...{
        "href": "/learn/module-4/self-enquiry/#signature-performances"
      }}>{`interfaces`}</a>{` (those 'boundaries' between distinct yet coextensive bodies) with others. Only once we have sincerely engaged in this ongoing process can we begin to see clearly and with less hubris what it might mean to `}<a parentName="p" {...{
        "href": "https://thesephist.com/posts/infinite/"
      }}>{`"assemble infinities out of finiteness"`}</a>{`.`}</p>
    <p>{`On this note, it is worth spending some time with the poets before you go. T. S. Elliot once wrote:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"They constantly try to escape`}<br parentName="p"></br>{`
`}{`From the darkness outside and within`}<br parentName="p"></br>{`
`}{`By dreaming of systems so perfect that no one will need to be good.`}<br parentName="p"></br>{`
`}{`But the man that is will shadow`}<br parentName="p"></br>{`
`}{`The man that pretends to be."  `}</p>
    </blockquote>
    <p>{`This search for the perfect language is a beautiful one, but it is tempered always by the standard to which all language must be held: what `}<a parentName="p" {...{
        "href": "/learn/module-7/no-paradigm/#measured-art"
      }}>{`Marianne Brün`}</a>{` calls "the imagery of human desire". When undertaking this search, it might help us to ask what "breathtaking eloquence and simple terms" explain "what we, today, almost speechlessly have wanted so much". `}</p>
    <p>{`My suggestion is that what we want is simply to be good. Happily, `}<a parentName="p" {...{
        "href": "https://www.henryzoo.com/limits"
      }}>{`the language of the good is also a language of self-imposed limits`}</a>{`, which seeks perfect expression only as a means towards realizing a convivial life lived together in `}<a parentName="p" {...{
        "href": "https://www.themarginalian.org/2014/03/12/jack-kerouac-golden-eternity/"
      }}>{`the golden mean`}</a>{`. This golden mean, or middle path, is akin to the game of least complexity, because it requires no additional symbol, only a kind of rhythmic harmony; a balance of the whole.`}</p>
    <h3 {...{
      "id": "many-worlds",
      "style": {
        "position": "relative"
      }
    }}>{`Many Worlds`}<a parentName="h3" {...{
        "href": "#many-worlds",
        "aria-label": "many worlds permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Pay Attention`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Image alt="Pay Attention" src="/images/mod7-img/mary_oliver.jpg" mdxType="Image" />
        </Aligner>
        <Aligner center mdxType="Aligner">
          <Button to="https://tinyurl.com/oliver-summer-day" mdxType="Button">
    Pray
          </Button>
        </Aligner>
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`The Perfection of Wisdom`}</strong></p>
        <Aligner center mdxType="Aligner">
    <Link to="https://www.youtube.com/watch?v=FZ0w4B80uZA" mdxType="Link">
        <Image alt="Form is Emptiness, Emptiness Form" src="/images/mod7-img/heart_sutra.jpg" mdxType="Image" />
    </Link>
        </Aligner>
        <Aligner center mdxType="Aligner">
          <Button to="https://www.sacred-texts.com/bud/tib/hrt.htm" mdxType="Button">
    To Empty Form
          </Button>
        </Aligner>
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      